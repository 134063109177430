import { graphql } from "gatsby";
import React from "react";
import AbschlussElemente from "../components/AbschlussElemente";
import DownloadsSektion from "../components/Downloads/DownloadsSektion";
import Landing from "../components/Landing";
import DefaultLayout from "../layouts/default";

interface DownloadsProps {
  data: {
    cms: {
      entry: {
        title: string;
        id: number;
        siteId: number;
        children: [
          {
            title: string;
            id: string;
            downloadsInhalte: [
              {
                id: number;
                downloadText: string;
                downloadDatei: [
                  {
                    url: string;
                  }
                ];
                linkText: string;
                linkUrl: string;
              }
            ];
          }
        ];
        metaBeschreibung: string;
        landingBild: [
          {
            url: string;
          }
        ];
        landingTitel: string;
        postLandingText: string;
        abschlussElemente: [
          {
            bild: [
              {
                url: string;
              }
            ];
            zitat: string;
            autor: string;
            buttonText: string;
            buttonLinkUrl: string;
            buttonLinkDatei: [
              {
                url: string;
              }
            ];
            buttonLinkTelefon: string;
            buttonLinkEmail: string;
            buttonIcon: string;
            inhaltstext: string;
          }
        ];
      };
    };
  };
}

export const DownloadsQuery = graphql`
  query ($id: CMS_QueryArgument, $siteId: CMS_QueryArgument) {
    cms {
      entry(id: [$id], siteId: [$siteId]) {
        title
        id
        siteId
        children {
          title
          ... on CMS_main_downloadSektion_Entry {
            downloadsInhalte {
              ... on CMS_downloadsInhalte_downloadItem_BlockType {
                downloadText
                downloadDatei {
                  url
                }
              }
              ... on CMS_downloadsInhalte_linkItem_BlockType {
                linkText
                linkUrl
              }
            }
          }
        }

        ... on CMS_main_downloads_Entry {
          metaBeschreibung
          landingTitel
          landingBild {
            url
          }
          abschlussElemente {
            ... on CMS_abschlussElemente_bild_BlockType {
              bild {
                url
              }
            }
            ... on CMS_abschlussElemente_zitat_BlockType {
              zitat
              autor
            }
            ... on CMS_abschlussElemente_button_BlockType {
              buttonText
              buttonLinkUrl
              buttonLinkDatei {
                url
              }
              buttonLinkTelefon
              buttonLinkEmail
              buttonIcon
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstext_BlockType {
              inhaltstext
            }
            ... on CMS_abschlussElemente_zentrierterInhaltstextBgColor_BlockType {
              titel
              text
            }
          }
        }
      }
    }
  }
`;

const Downloads: React.FC<DownloadsProps> = ({ data }) => {
  return (
    <DefaultLayout
      siteId={data.cms.entry.siteId}
      description={data.cms.entry.metaBeschreibung}
      title={data.cms.entry.title}
    >
      {data.cms.entry.landingBild && data.cms.entry.landingBild.length > 0 ? (
        <Landing
          title={data.cms.entry.landingTitel}
          image={data.cms.entry.landingBild[0].url}
        />
      ) : (
        ""
      )}
      {data.cms.entry.children.map((item, index) => (
        <DownloadsSektion content={item} key={index} />
      ))}
      {data.cms.entry.abschlussElemente.length > 0 ? (
        <AbschlussElemente
          content={data.cms.entry.abschlussElemente}
          siteId={data.cms.entry.siteId}
        />
      ) : (
        ""
      )}
    </DefaultLayout>
  );
};

export default Downloads;
