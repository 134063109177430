import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";
import Wrapper from "../Wrappers/Wrapper";
import Item from "./Item";
import Title from "./Title";

const Section = styled.section`
  width: 100%;
  margin: 30px 0 30px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    margin-bottom: 45px 0 45px;
  }
`;

interface DownloadSektionProps {
  content: {
    title: string;
    id: string;
    downloadsInhalte: [
      {
        id: number;
        downloadText: string;
        downloadDatei: [
          {
            url: string;
          }
        ];
        linkText: string;
        linkUrl: string;
      }
    ];
  };
}

const DownloadsSektion: React.FC<DownloadSektionProps> = ({ content }) => (
  <Wrapper>
    <Section>
      <Title>{content.title}</Title>
      {content.downloadsInhalte.map((item, index) =>
        item.downloadText ? (
          <Item
            link={item.downloadDatei[0].url}
            text={item.downloadText}
            download
            key={index}
          />
        ) : (
          <Item link={item.linkUrl} text={item.linkText} key={index} />
        )
      )}
    </Section>
  </Wrapper>
);

export default DownloadsSektion;
