import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const TitleElem = styled.h2`
  width: 100%;
  font: 20px / 30px "FS Medium";
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.text};
  margin-bottom: 24px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    font: 28px / 36px "FS Medium";
    padding-bottom: 16px;
    margin-bottom: 38px;
  }
`;

const Title: React.FC = ({ children }) => <TitleElem>{children}</TitleElem>;

export default Title;
