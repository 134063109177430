import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const ItemElem = styled.a`
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-column-gap: 20px;
  margin-bottom: 20px;
  text-decoration: none;

  .icon {
    margin-top: 0px;
    img {
      width: 20px;
    }
  }

  p {
    font: 16px / 21px "FS Regular";
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 26px 1fr;
    grid-column-gap: 40px;
    margin-bottom: 26px;
    .icon {
      margin-top: 0;

      img {
        width: 26px;
        height: 26px;
      }
    }
    p {
      font: 21px / 28px "FS Regular";
    }
  }
`;

interface ItemProps {
  link: string;
  text: string;
  download?: boolean;
}

const Item: React.FC<ItemProps> = ({ link, text, download }) => (
  <ItemElem href={link} target="_blank">
    <div className="icon">
      {download ? (
        <img
          src="/assets/gestaltungselemente/icon-download-black.svg"
          alt="Herunterladen Icon"
        />
      ) : (
        <img
          src="/assets/gestaltungselemente/icon-link-black.svg"
          alt="Link Icon"
        />
      )}
    </div>
    <div>
      <p>{text}</p>
    </div>
  </ItemElem>
);

export default Item;
